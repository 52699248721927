import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import BlogListing from '../components/blog/listing'
import Pagination from '../components/layout/pagination'
import { Layout } from '../components/global/layout'
import Masthead from '../components/layout/masthead'

const OurInsightIndex = ({ data, pageContext }) => {
  if (!data) 
    return null

  const document = data.prismicOurInsightIndex
  const articles = data.allPrismicInsightArticle

  return (
    <Layout pageTitle={document.data.title.text} 
            metaTitle={document.data.meta_title.text} 
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <Masthead subtitle={document.data.subtitle.text}
                title={document.data.title.text}
                summary={document.data.summary.text} />
      <BlogListing data={articles} tags={pageContext.allTags} basePath={document.url} />
      <Pagination pageInfo={articles.pageInfo} basePath={document.url} />
    </Layout>
  )
}

export const query = graphql`
  query OurInsightIndexPageQuery($limit: Int!, $skip: Int!) {
    prismicOurInsightIndex {
      _previewable
        data {
          title {
            text
          }
          subtitle {
            text
          }
          summary {
            text
          }
          meta_title {
            text
          }
          meta_description {
            text
          }
          share_image {
            url
          }
        }
        url
        tags
    }
    allPrismicInsightArticle(
      limit: $limit
      skip: $skip
      sort: {fields: data___date, order: DESC}
      ) {
      nodes {
        data {
          title {
            text
          }
          summary {
            text
          }
          date
          listing_image {
            alt
            url
          }
        }
        url
        uid
        tags
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

export default withPrismicPreview(OurInsightIndex, repositoryConfigs)